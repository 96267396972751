import config from "../../../config";
import store from "../../store/index";
import { format_date } from "../../util/utils";

export default {
  namespaced: true,
  state: {
  },
  mutations: {

  },
  actions: {
    async get_recibo_data_print({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(
            `${config.API_URL}/planes/recibos/${payload.id}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: store.state.token,
              },
            }
          );

          const data = await res.json();

          if (data.exito == 1) {
            resolve(data);
          } else {
            reject(data);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    async anular_recibo({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.API_URL}/planes/recibos/${payload.id}/anular`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: store.state.token,
            }
          });

          const data = await res.json();

          if (data.exito == 1) {
            resolve(data);
          } else {
            reject(data);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    async rendir_recibo({},payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.API_URL}/planes/recibos/${payload}/rendir`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: store.state.token,
            },
          });

          const data = await res.json();

          if (data.exito == 1) {
            resolve(data);
          } else {
            reject(data);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    async editar_recibo({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {

          const body = {
            preimpreso: payload.preimpreso,
            importe: payload.importe,
            nombre: payload.nombre,
            apellido: payload.apellido,
            num_doc: payload.documento,
            tipo_doc: payload.tipo_doc_id,
            telefono: payload.telefono, 
            email: payload.email
        }
          const res = await fetch(`${config.API_URL}/planes/recibos/${payload.id}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: store.state.token,
            },
            body: JSON.stringify(body)
          });

          const data = await res.json();

          if (data.exito == 1) {
            resolve(data);
          } else {
            reject(data);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    async verificar_permiso_anulacion() {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.API_URL}/planes/recibos/permisos`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: store.state.token,
            },
          });

          const data = await res.json();

          if (data.exito == 1) {
            resolve(data);
          } else {
            reject(data);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    async verificar_permiso_edicion() {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(
            `${config.API_URL}/planes/recibos/permisos`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: store.state.token,
              },
            }
          );

          const data = await res.json();

          if (data.exito == 1) {
            resolve(data);
          } else {
            reject(data);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    async get_recibos({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          payload.marca = payload.marca || ""
          payload.sucursal = payload.sucursal || ""
          payload.suscripcion = payload.suscripcion || ""
          payload.estado = payload.estado || ""
          payload.desde = payload.fechaDesde || ""
          payload.hasta = payload.fechaHasta || ""

          const params = new URLSearchParams({
            familia: payload.empresa,
            empresa: payload.marca,
            sucursal: payload.sucursal,
            preimpreso: payload.suscripcion,
            estado: payload.estado,
            desde: payload.desde,
            hasta: payload.hasta,
          });

          const res = await fetch(
            `${config.API_URL}/planes/recibos/search?${params.toString()}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: store.state.token,
              },
            }
          );

          const data = await res.json();

          if (data.exito == 1) {
            resolve(data);
          } else {
            reject(data);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    async nuevo_recibo({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {

          const body = {
            empresa: payload.empresa_id,
            sucursal: payload.sucursal_id,
            preimpreso: payload.preimpreso,
            importe: payload.importe,
            nombre: payload.nombre,
            apellido: payload.apellido,
            num_doc: payload.documento,
            tipo_doc: payload.tipo_doc_id,
            telefono: payload.telefono,
            email: payload.email
          }
          const res = await fetch(`${config.API_URL}/planes/recibos`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: store.state.token,
            },
            body: JSON.stringify(body),
          });

          const data = await res.json();

          if (data.exito == 1) {
            resolve(data);
          } else {
            reject(data);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    async get_estados() {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.API_URL}/planes/recibos/estados`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: store.state.token,
            },
          });

          const data = await res.json();
          if (data.exito == 1) {
            resolve(data);
          } else {
            reject(data);
          }
        } catch (error) {
          reject(error);
        }
      });
    },

  },
};
