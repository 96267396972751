import { API_URL } from '../../../config'
import store from '../../store'


export default {
  namespaced: true,
  actions: {

    async getModelos(){
      try {
        const response = await fetch(`${API_URL}/pa/preciosModelos/modelos`, {
          method: 'GET',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          }
        })

        return response.json()

      } catch (error) {
        return {
          exito: 0,
          message: `Ocurrió un error al ejecutar el metodo getModelos ${error}`
        }
      }
    },

    async getPrecios(){
      try {
        const response = await fetch(`${API_URL}/pa/preciosModelos/precios`, {
          method: 'GET',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          }
        })

        return response.json()

      } catch (error) {
        return {
          exito: 0,
          message: `Ocurrió un error al ejecutar el metodo getModelos ${error}`
        }
      }
    },

    async getPreciosModelos({}, payload){
      try {
        const params = new URLSearchParams({
          Marca_id: payload
        })

        const response = await fetch(`${API_URL}/pa/preciosModelos/precios?${params.toString()}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          }
        })

        return response.json()

      } catch (error) {
        return {
          exito: 0,
          message: `Ocurrió un error al ejecutar el metodo getPreciosModelos ${error}`
        }
      }
    },

    async postPreciosModelos({}, payload) {

      let precios = payload

      try {
        const response = await fetch(`${API_URL}/pa/preciosModelos/precios`, {
          method: 'POST',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          },
          body: JSON.stringify(precios)
        })

        return response.json()

      } catch (error) {
        return {
          exito: 0,
          message: `Ocurrió un error al ejecutar el metodo postPreciosModelos ${error}`
        }
      }
    },

    async eliminarPrecioModelo({}, payload){

      const precio = payload

      try {
        const response = await fetch(`${API_URL}/pa/preciosModelos/eliminarPrecio`, {
          method: 'POST',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          },
          body: JSON.stringify(precio)
        })

        return response.json()

      } catch (error) {
        return {
          exito: 0,
          message: `Ocurrió un error al ejecutar el metodo eliminarPrecioModelo ${error}`
        }
      }
    },

    async postPreciosMasivo({}, payload) {
      
      let precios = payload
      try {
        const response = await fetch(`${API_URL}/pa/preciosModelos/preciosMasivo`, {
          method: 'POST',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          },
          body: JSON.stringify(precios)
        })

        return response.json()

      } catch (error) {
        return {
          exito: 0,
          message: `Ocurrió un error al ejecutar el metodo postPreciosMasivo ${error}`
        }
      }
    },


    async editarPrecioModelo({}, payload){

      const precio = payload

      try {
        const response = await fetch(`${API_URL}/pa/preciosModelos/editarPrecio`, {
          method: 'POST',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          },
          body: JSON.stringify(precio)
        })

        return response.json()

      } catch (error) {
        return {
          exito: 0,
          message: `Ocurrió un error al ejecutar el metodo editarPrecioModelo ${error}`
        }
      }
    },

  }
}