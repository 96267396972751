import config from '../../../config'
import store from '../../store'

export default {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  actions: {
    async get_cartera ({}, fto) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.API_URL}/planes/cartera/search?empresa=${fto.empresa}&marca=${fto.marca}&preimpreso=${fto.preimpreso}&grupo=${fto.grupo}&orden=${fto.orden}&titular=${fto.titular}&documento=${fto.documento}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })
    
          const data = await res.json()
    
          resolve(data)
    
        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async nuevo_comentario ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.API_URL}/planes/cartera/comentario`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            },
            body: JSON.stringify(payload)
          })
    
          const data = await res.json()
    
          resolve(data)
    
        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    }
  }
}