<template>
  <v-app-bar
    color="rojoDycar"
    style="z-index: 2"
    :flat="$vuetify.breakpoint.mdAndUp"
    dense
    dark
    app
  >
    <v-app-bar-nav-icon
      @click.stop="$vuetify.breakpoint.smAndDown ? drawer = !drawer : expand = !expand"
    ></v-app-bar-nav-icon>
    <!-- Obtiene el nombre del menu -->
    <span style="cursor: default;">
    {{
      $store.state.menu_nombres.find(item => item.ruta == $route.path) ?
      $store.state.menu_nombres.find(item => item.ruta == $route.path).nombre :
      $route.name
    }}
    </span>
    <v-spacer></v-spacer>
    <!-- boton para el tema -->
    <v-btn
      :title="$vuetify.theme.dark ? 'Tema claro' : 'Tema oscuro'"
      :loading="load"
      icon
      @click="set_theme()"
    >
      <v-icon>
        fas fa-{{ $vuetify.theme.dark ? 'sun' : 'moon' }}
      </v-icon>
    </v-btn>

    <!-- menú de notificaciones -->
   
    <v-menu
      max-width="300"
      transition="scale-transition"
      :origin="$vuetify.breakpoint.smAndDown ? 'center top' : 'right top'"
      :nudge-width="250"
      :nudge-left="$vuetify.breakpoint.smAndDown ? 0 : 250"
      :disabled="$route.name === 'Notificaciones'"
      :close-on-content-click="false"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          title="Notificaciones"
          :disabled="$route.name === 'Notificaciones'"
          icon
        >
          <v-badge
            :content="$store.state.notificaciones.length"
            :value="$store.state.notificaciones.length"
            color="error"
            overlap
          >
            <v-icon>fas fa-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>
      <ModalNotificaciones />
    </v-menu>


    <!-- menu de usuario -->
    <v-menu offset-y left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          icon
        >
          <v-icon>fas fa-user-cog</v-icon>
        </v-btn>
      </template>
      <v-list class="py-2">
        <v-list-item
          class="my-n1"
          style="cursor: pointer"
          @click.stop="dialog_clave = true"
        >
          <v-list-item-title>
            <v-icon left small>fas fa-key</v-icon>
            Cambiar clave
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="$store.state.usuario.cambiaPin === 1"
          class="my-n1"
          style="cursor: pointer"
          @click.stop="dialog_pin = true"
        >
          <v-list-item-title>
            <v-icon left small>fas fa-ellipsis-h</v-icon>
            Cambiar PIN
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          class="my-n1"
          style="cursor: pointer"
          @click.stop="sincronizar()"
        >
          <v-list-item-title>
            <v-icon left small>fas fa-sync</v-icon>
            Sincronizar
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <!-- nombre de usuario -->
    <div
      class="d-flex align-center font-weight-medium px-2"
      style="font-size: 14px; letter-spacing: 1.374px; cursor: default;"
      :icon="$vuetify.breakpoint.smAndDown"
    >
      <v-avatar size="36">
        <v-icon>fas fa-user-circle</v-icon>
      </v-avatar>
      <div v-if="!$vuetify.breakpoint.smAndDown" class="ml-2">{{ $store.state.username }}</div>
    </div>
    <!-- logout -->
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          icon
          @click="logout()"
        >
          <v-icon>fas fa-sign-out-alt</v-icon>
        </v-btn>
      </template>
      <span>Cerrar sesión</span>
    </v-tooltip>
    <CambiarClave
      v-model="dialog_clave"
    />
    <CambiarPIN
      v-model="dialog_pin"
    />
  </v-app-bar>
</template>

<script>
import CambiarClave from './components/CambiarClave'
import CambiarPIN from './components/CambiarPIN'
import ModalNotificaciones from './components/ModalNotificaciones'
import store from './store'

export default {
  data () {
    return {
      dialog_clave: false,
      dialog_pin: false,
      load: false
    }
  },
  created () {
    this.$vuetify.theme.dark = JSON.parse(localStorage.getItem('dark'))
  },
  props: {
    p_expand: Boolean,
    p_drawer: Boolean
  },
  components: {
    CambiarClave,
    CambiarPIN,
    ModalNotificaciones
},
  computed: {
    expand: {
      get () {
        return this.p_expand
      },
      set (value) {
        this.$emit('update:p_expand', value)
      }
    },
    drawer: {
      get () {
        return this.p_drawer
      },
      set (value) {
        this.$emit('update:p_drawer', value)
      }
    }
  },
  methods: {
    async sincronizar () {
      this.$store.state.loading = true
      await this.$store.dispatch('get_user_data')
        .then(() => {
          this.$store.dispatch('show_snackbar', {
            text: 'Sincronización de perfil exitosa',
            color: 'success'
          })
          window.location.reload(true)
        })
        .catch((error) => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async set_theme () {
      this.load = true
      await this.$store.dispatch('set_theme', !this.$vuetify.theme.dark)
        .then((res) => {
          this.$vuetify.theme.dark = !this.$vuetify.theme.dark
          this.$store.dispatch('show_snackbar', {
            text: 'Tema cambiado correcamente',
            color: 'success'
          })
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.load = false
    },
    async logout () {
      await this.$store.dispatch('logout')
        .then(async () => {
          await this.$router.push({ path: "/login" })
        })
        .catch(error => {
          dispatch('show_snackbar', {
            color: 'error',
            text: error.message
          })
        })
      this.$vuetify.theme.dark = false
    }
  }
}
</script>