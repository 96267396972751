import {
  sendPasswordResetEmail,
  updatePassword,
  EmailAuthProvider,
  reauthenticateWithCredential
} from 'firebase/auth'
import { auth } from '../firebase'
import config, { API_JARVIS } from '../../config'
import store from '../store'
import { Encrypt } from '../util/aes'
import { order_list_by } from '../util/utils'

export default {
  namespaced: true,
  state: {
    roles: []
  },
  mutations: {
    set_roles(state, payload) {
      order_list_by(payload, 'nombre')
      state.roles = payload
    }
  },
  actions: {
    async habilitar_usuario({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(
            `${config.API_URL}/user/${payload.id}/habilitar`,
            {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
                Authorization: store.state.token
              },
              body: JSON.stringify(payload)
            }
          )

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
        } catch (error) {
          reject(error)
        }
      })
    },
    async inhabilitar_usuario({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(
            `${config.API_URL}/user/${payload.id}/inhabilitar`,
            {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
                Authorization: store.state.token
              },
              body: JSON.stringify(payload)
            }
          )

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
        } catch (error) {
          reject(error)
        }
      })
    },
    async editar_roles({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(
            `${config.API_URL}/user/${payload.id}/roles`,
            {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
                Authorization: store.state.token
              },
              body: JSON.stringify(payload)
            }
          )

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
        } catch (error) {
          reject(error)
        }
      })
    },
    async get_roles_usuario({}, id) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.API_URL}/user/${id}/roles`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: store.state.token
            }
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
        } catch (error) {
          reject(error)
        }
      })
    },
    async get_roles({ state, commit }) {
      try {
        if (state.roles.length == 0) {
          const res = await fetch(`${config.API_URL}/user/roles`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: store.state.token
            }
          })

          const data = await res.json()

          if (data.exito == 1) {
            commit('set_roles', data.data)
          } else {
            commit('set_roles', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
        }
      } catch (error) {
        commit('set_roles', [])
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
    },
    async get_usuarios({}, filtro) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(
            `${config.API_URL}/user/search?username=${filtro.username}&email=${filtro.email}&rol=${filtro.rol}&empresa=${filtro.empresa}&inhabilitado=${filtro.inhabilitado}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: store.state.token
              }
            }
          )

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
        } catch (error) {
          reject(error)
        }
      })
    },
    async editar_usuario({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.API_URL}/user/${payload.id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: store.state.token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
        } catch (error) {
          reject(error)
        }
      })
    },
    async nuevo_usuario({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          let usuario = JSON.parse(JSON.stringify(payload))
          usuario.email = Encrypt(usuario.email)
          usuario.clave = Encrypt(usuario.clave)

          const res = await fetch(`${config.API_URL}/user`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: store.state.token
            },
            body: JSON.stringify(usuario)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
        } catch (error) {
          reject(error)
        }
      })
    },
    async cambiar_clave({}, payload) {
      return await new Promise(async (resolve, reject) => {
        // obtiene las credenciales del usuario para reautenticarlo
        const user = auth.currentUser
        const credential = EmailAuthProvider.credential(
          user.email,
          payload.pass_actual
        )
        await reauthenticateWithCredential(user, credential)
          .then(async () => {
            // actualizamos la contraseña con el usuario reautenticado
            await updatePassword(user, payload.pass_nueva)
              .then(() => {
                resolve()
              })
              .catch(error => {
                switch (error.code) {
                  case 'auth/too-many-requests':
                    error.message =
                      'Superó el límite de intentos de cambio de clave, intente más tarde'
                    break
                }
                reject(error)
              })
          })
          .catch(error => {
            switch (error.code) {
              case 'auth/wrong-password':
                error.message = 'Clave actual incorrecta'
                break
              case 'auth/too-many-requests':
                error.message =
                  'Superó el límite de intentos de cambio de clave, intente más tarde'
                break
            }
            reject(error)
          })
      })
    },
    async recuperar_clave({}, payload) {
      return await new Promise(async (resolve, reject) => {
        await sendPasswordResetEmail(auth, payload)
          .then(() => {
            resolve()
          })
          .catch(error => {
            // cacheamos los errores de firebase para traducirlos
            switch (error.code) {
              case 'auth/user-not-found':
                error.message = 'Email inexistente'
                break
              case 'auth/too-many-requests':
                error.message =
                  'Superó el límite de intentos de recuperación de clave, intente más tarde'
                break
              case 'auth/invalid-email':
                error.message = 'Ingrese un email válido'
                break
            }
            reject(error)
          })
      })
    },
    async get_empresas_usuario({}, id) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.API_URL}/user/${id}/empresas`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: store.state.token
            }
          })

          const data = await res.json()

          resolve(data)
        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async editar_empresas({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(
            `${config.API_URL}/user/${payload.id}/empresas`,
            {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
                Authorization: store.state.token
              },
              body: JSON.stringify(payload)
            }
          )

          const data = await res.json()

          resolve(data)
        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },

    async altaJarvis({}, payload) {
      const response = await fetch(`${API_JARVIS}/user/altaStark`, {
        method: 'POST',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.jarvis_token
        },
        body: JSON.stringify(payload)
      })
      return response.json()
    }
  }
}
