// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
import { getDatabase } from "firebase/database"
import { getAuth } from "firebase/auth"
import { getStorage } from "firebase/storage" 

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyB6ym9evwoCJSycqCB-WfnqdDOLrfNFdHs",
  authDomain: "dycarsistemas.firebaseapp.com",
  databaseURL: "https://dycarsistemas-default-rtdb.firebaseio.com",
  projectId: "dycarsistemas",
  storageBucket: "dycarsistemas.appspot.com",
  messagingSenderId: "555604682649",
  appId: "1:555604682649:web:bbe4307ae5c5e1ce2fd508",
  measurementId: "G-5BHGBZB4ZJ"
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

// Initialize Realtime Database and get a reference to the service
export const database = getDatabase(app)

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app)
export const storage = getStorage(app)