import { API_URL } from '../../../config'
import store from '../../store'

export default {
  namespaced: true,

  actions: {

    async getModelos({}, payload) {
      try {
        const params = new URLSearchParams({
          Empresa_id: payload
        })

        const response = await fetch(`${API_URL}/pa/modelos/getModelos?${params.toString()}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          }
        })
        return response.json()
      } catch (error) {
        return {
          exito: 0,
          message: error.message
        }
      }
    },


    async sincronizarModelos({}, payload) {
      const params = new URLSearchParams({
        Empresa_id: payload
      })

      try {
        const response = await fetch(`${API_URL}/pa/modelos/sincronizar?${params.toString()}}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          },
        })
        return response.json()
      } catch (error) {
        return {
          exito: 0,
          message: error.message
        }
      }
    },

    async gestionarModelo({ }, payload) {
      try {
        const response = await fetch(`${API_URL}/pa/modelos/gestionar`, {
          method: 'POST',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token,
          },
          body: JSON.stringify(payload)
        });

        return response.json();
      } catch (error) {
        return {
          exito: false,
          message: error.message
        };
      }
    }


  }
}