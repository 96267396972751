import { API_URL } from '../../config'
import store from '../store'

export default {
  namespaced: true,
  actions: {
    async getVehiculosUsados() {
      try {
        const getVehiculos = await fetch(`${API_URL}/usados/vehiculos`, {
          method: 'GET',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          }
        })

        return getVehiculos.json()
      } catch (error) {
        return {
          resultado: 0,
          msj:
            'Ocurrio un problema al ejecutar el metodo getVehiculosUsados: ' +
            error.message
        }
      }
    },

    async getVehiculosPrecios() {
      try {
        const response = await fetch(`${API_URL}/usados/usadosdycar`, {
          method: 'GET',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          }
        })

        return response.json()
      } catch (error) {
        return {
          exito: 0,
          messaje:
            'Ocurrio un problema al traer los vehiculos para gestion' +
            error.messaje
        }
      }
    },

    async getMarcasUsados() {
      try {
        const getMarcas = await fetch(`${API_URL}/usados/marcas`, {
          method: 'GET',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          }
        })

        const marcasUsados = await getMarcas.json()
        return marcasUsados
      } catch (error) {
        return {
          resultado: 0,
          msj:
            'Ocurrio un problema al ejecutar el metodo getMarcasUsados: ' +
            error.message
        }
      }
    },

    async getPrecios() {
      try {
        const getPrecios = await fetch(`${API_URL}/usados/filtroPrecio`, {
          method: 'GET',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          }
        })

        return getPrecios.json()
      } catch (error) {
        return {
          exito: 0,
          msj:
            'Ocurrio un problema al ejecutar el metodo getPrecios: ' +
            error.message
        }
      }
    },

    async getInfo() {
      try {
        const getInfo = await fetch(`${API_URL}/usados/info`, {
          method: 'GET',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          }
        })

        return getInfo.json()
      } catch (error) {
        return {
          exito: 0,
          msj:
            'Ocurrio un problema al traer la información de los usados en gestión: ' +
            error.message
        }
      }
    },

    async sincronizar() {
      try {
        const sync = await fetch(`${API_URL}/usados/sincronizar`, {
          method: 'GET',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          }
        })

        return sync.json()
      } catch (error) {
        return {
          exito: 0,
          msj:
            'Ocurrio un problema al sincronizar los vehiculos: ' + error.message
        }
      }
    },

    async editarUsado({}, payload) {
      try {
        const result = await fetch(`${API_URL}/usados/editar`, {
          method: 'POST',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          },
          body: JSON.stringify(payload)
        })

        return result.json()
      } catch (error) {
        return {
          exito: 0,
          msj: 'Error al editar el vehiculo usado' + error.message
        }
      }
    },

    async getPreciosVigentes() {
      try {
        const result = await fetch(`${API_URL}/usados/preciosVigentes`, {
          method: 'GET',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          }
        })

        return result.json()
      } catch (error) {
        return {
          exito: 0,
          msj: 'Error al traer precios vigentes' + error.message
        }
      }
    },

    async publicarPreciosUsados({}, payload) {
      try {
        const publicarPrecios = await fetch(
          `${API_URL}/usados/publicarVehiculosFB`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: store.state.token
            },
            body: JSON.stringify(payload)
          }
        )

        const response = await publicarPrecios.json()
        return response
      } catch (error) {
        return {
          exito: 0,
          msj: 'Fallo el metodo publicar precios usados.',
          error
        }
      }
    },

    async publicarPreciosAutodealer({}, payload) {
      try {
        const publicarAutodealer = await fetch(
          `${API_URL}/usados/publicarPreciosAutodealer`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: store.state.token
            },
            body: JSON.stringify(payload)
          }
        )
        return publicarAutodealer.json()
      } catch (error) {
        return {
          exito: 0,
          msj: 'Ocurrió un error al ejecutar el método publicarPreciosAutodealer',
          error
        }
      }
    },

    async inhabilitarVehiculo({}, payload) {
      const response = await fetch(`${API_URL}/usados/inhabilitar`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: store.state.token
        },
        body: JSON.stringify(payload)
      })

      return response.json()
    },

    async habilitarVehiculo({}, payload) {
      const response = await fetch(`${API_URL}/usados/habilitar`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: store.state.token
        },
        body: JSON.stringify(payload)
      })

      return response.json()
    },

    async getVehiculosInhabilitados() {
      const response = await fetch(`${API_URL}/usados/vehiculosInhabilitados`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: store.state.token
        },
      })
      return response.json()
    },

    async getPermisoHabilitar() {
      const response = await fetch(`${API_URL}/usados/permisoHabilitar`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: store.state.token
        },
      })
      return response.json()
    },
  }
}
