import { API_URL } from '../../../config'
import store from '../../store'

export default {
  namespaced: true,
  actions: {
    async getVouchers({}, payload) {
      const params = new URLSearchParams({
        ...payload
      })

      const response = await fetch(`${API_URL}/vouchers?${params.toString()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        }
      })

      return response.json()
    },

    async getVoucher({}, payload) {
      const params = new URLSearchParams({
        ...payload
      })

      const response = await fetch(`${API_URL}/vouchers/voucher?${params.toString()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        }
      })

      return response.json()
    },

    async getTiposCombustible() {
      const response = await fetch(`${API_URL}/vouchers/tipos-combustible`, {
        method: 'GET',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        }
      })

      return response.json()
    },

    async getSectores() {
      const response = await fetch(`${API_URL}/vouchers/sectores`, {
        method: 'GET',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        }
      })

      return response.json()
    },

    async crearVoucher({}, payload) {
      const response = await fetch(`${API_URL}/vouchers/crear`, {
        method: 'POST',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        },
        body: JSON.stringify(payload)
      })

      return response.json()
    },

    async editarVoucher({}, payload) {
      const response = await fetch(`${API_URL}/vouchers/editar`, {
        method: 'POST',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        },
        body: JSON.stringify(payload)
      })

      return response.json()
    },

    async getImagenesEstaciones() {
      const response = await fetch(`${API_URL}/vouchers/imagenes-estaciones`, {
        method: 'GET',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        }
      })

      return response.json()
    },

    async getPlantilla() {
      const response = await fetch(`${API_URL}/vouchers/plantilla`, {
        method: 'GET',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        }
      })

      return response.json()
    },
  }
}
