import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import config from '../../config'
import { VueEasyJwt } from 'vue-easy-jwt'

const jwt = new VueEasyJwt()
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    meta: { requireAuth: true }
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/patentamiento',
    name: 'Patentamiento',
    component: () =>
      import(
        /* webpackChunkName: "patentamiento" */ '../views/Patentamiento.vue'
      ),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/instructivos',
    name: 'Instructivos',
    component: () =>
      import(
        /* webpackChunkName: "instructivos" */ '../views/Instructivos.vue'
      ),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/archivos',
    name: 'Importar Archivos',
    component: () =>
      import(/* webpackChunkName: "archivos" */ '../views/Archivos.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/actualizar/servicios',
    name: 'Actualizar Servicios',
    component: () =>
      import(
        /* webpackChunkName: "actualizar" */ '../views/ActualizarServicios.vue'
      ),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  // ----------------------------------
  //           CONFIGURACION
  // ----------------------------------
  // sufijo: /conf
  {
    path: '/conf/menu',
    name: 'Menu',
    component: () =>
      import(/* webpackChunkName: "admin" */ '../views/admin/MenuLat.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/conf/roles',
    name: 'Roles',
    component: () =>
      import(/* webpackChunkName: "admin" */ '../views/admin/Roles.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/conf/usuarios',
    name: 'Usuarios',
    component: () =>
      import(/* webpackChunkName: "admin" */ '../views/admin/Usuarios.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/conf/rutas',
    name: 'Rutas',
    component: () =>
      import(/* webpackChunkName: "admin" */ '../views/admin/Rutas.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/conf/funciones',
    name: 'Funciones',
    component: () =>
      import(/* webpackChunkName: "admin" */ '../views/admin/Funciones.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  // ----------------------------------
  //           COBRO ONLINE
  // ----------------------------------
  {
    path: '/cobro-online',
    name: 'Cobro Online',
    component: () =>
      import(/* webpackChunkName: "cobro" */ '../views/CobroOnline.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },

  // ----------------------------------
  //           NOTIFICACIONES
  // ----------------------------------
  {
    path: '/notificaciones',
    name: 'Notificaciones',
    component: () =>
      import(
        /* webpackChunkName: "notificaciones" */ '../views/Notificaciones.vue'
      ),
    meta: { requireAuth: true }
  },

  // ----------------------------------
  //            USADOS
  // ----------------------------------
  // sufijo: /usados

  {
    path: '/usados/vehiculos',
    name: 'Vehiculos',
    component: () =>
      import(/* webpackChunkName: "usados" */ '../views/usados/Vehiculos.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },

  {
    path: '/usados/vehiculos/:id?',
    name: 'Detalle de Vehiculo',
    component: () =>
      import(
        /* webpackChunkName: "usados" */ '../components/usados/DetalleVehiculo.vue'
      ),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },

  {
    path: '/usados/precios',
    name: 'Precios',
    component: () =>
      import(/* webpackChunkName: "usados" */ '../views/usados/Precios.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  // ----------------------------------
  //           COMISIONES PLANES DE AHORRO
  // ----------------------------------
  // sufijo: /pa
  {
    path: '/pa/cartera',
    name: 'Cartera',
    component: () =>
      import(/* webpackChunkName: "pa" */ '../views/pa/Cartera.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/pa/comisiones',
    name: 'Comisiones',
    component: () =>
      import(/* webpackChunkName: "pa" */ '../views/pa/Comisiones.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/pa/precios-modelos',
    name: 'Precios - Modelos',
    component: () =>
      import(/* webpackChunkName: "pa" */ '../views/pa/PreciosModelos.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  // ----------------------------------
  //           PLANES DE AHORRO
  // ----------------------------------
  // sufijo: /planes
  {
    path: '/planes/recuperos',
    name: 'Recuperos',
    component: () =>
      import(/* webpackChunkName: "planes" */ '../views/planes/Recuperos.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/planes/ventas',
    name: 'Ventas',
    component: () =>
      import(/* webpackChunkName: "planes" */ '../views/planes/Ventas.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/planes/mantenimiento',
    name: 'Mantenimiento',
    component: () =>
      import(
        /* webpackChunkName: "planes" */ '../views/planes/Mantenimiento.vue'
      ),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/planes/dashboard',
    name: 'Dashboard',
    component: () =>
      import(/* webpackChunkName: "planes" */ '../views/planes/Dashboard.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/planes/cartera',
    name: 'Cartera PA',
    component: () =>
      import(/* webpackChunkName: "planes" */ '../views/planes/Cartera.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/planes/recibos-efectivo',
    name: 'Recibos en efectivo',
    component: () =>
      import(
        /* webpackChunkName: "recivos-efectivo" */ '../views/planes/RecibosEfectivo.vue'
      ),
    meta: { requireAuth: true, rutaProtegida: true }
  },
  // ----------------------------------
  //           TALLER
  // ----------------------------------
  // sufijo: /taller
  {
    path: '/taller/ordenes-servicio',
    name: 'Ordenes de Servicio',
    component: () =>
      import(
        /* webpackChunkName: "taller" */ '../views/taller/OrdenesServicio.vue'
      ),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },

  // ----------------------------------
  //            ADMINISTRACIÓN
  // ----------------------------------
  // sufijo: /administracion

  {
    path: '/administracion/comprobantes',
    name: 'Comp. Afip',
    component: () =>
      import(
        /* webpackChunkName: "administracion" */ '../views/administracion/ComprobantesAfip.vue'
      ),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/administracion/retenciones-percepciones',
    name: 'Retenciones y percepciones',
    component: () =>
      import(
        /* webpackChunkName: "administracion" */ '../views/administracion/RetencionesPercepciones.vue'
      ),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },

  {
    path: '/administracion/talonarios',
    name: 'ABM Talonarios',
    component: () =>
      import(
        /* webpackChunkName: "administracion" */ '../views/administracion/ABMTalonarios.vue'
      ),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },

  {
    path: '/administracion/formularios',
    name: 'Gestion Formularios',
    component: () =>
      import(
        /* webpackChunkName: "administracion" */ '../views/administracion/GestionFormularios.vue'
      ),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },

  {
    path: '/administracion/recibos-combustible',
    name: 'Recibos combustible',
    component: () =>
      import(
        /* webpackChunkName: "administracion" */ '../views/administracion/RecibosCombustible.vue'
      ),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },

  // ----------------------------------
  //            COMISIONES SALON
  // ----------------------------------
  // sufijo: /comisiones-vn
  {
    path: '/comisiones-vn/ventas',
    name: 'Ventas salon',
    component: () =>
      import(
        /* webpackChunkName: "comisiones-vn" */ '../views/comisiones-salon/Ventas.vue'
      ),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },
  {
    path: '/comisiones-vn/comisiones',
    name: 'Comisiones VN',
    component: () =>
      import(
        /* webpackChunkName: "comisiones-vn" */ '../views/comisiones-salon/Comisiones.vue'
      ),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },

  // ----------------------------------
  //           INCIDENTES
  // ----------------------------------
  {
    path: '/incidentes',
    name: 'Incidentes',
    component: () =>
      import(
        /* webpackChunkName: "cobro" */ '../views/incidentes/Incidentes.vue'
      ),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  },

  // ----------------------------------
  //           DESCARGAR ARCHIVO
  // ----------------------------------
  {
    path: '/descargarArchivo',
    name: 'Descargar Archivo',
    component: () =>
      import(
        /* webpackChunkName: "descargar-archivo" */ '../views/DescargarArchivo.vue'
      ),
    meta: { requireAuth: true }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requireAuth)) {
    if (is_auth()) {
      setTimeout(() => {
        store.dispatch('get_notificaciones_noleidas')
      }, 1)
      if (to.matched.some(record => record.meta.rutaProtegida)) {
        try {
          store.state.loading = true
          const res = await fetch(`${config.API_URL}/user/rutaValida`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: JSON.parse(localStorage.getItem('token'))
            },
            body: JSON.stringify({
              path: to.path,
              redirect: true
            })
          })
          const data = await res.json()
          if (data.exito == 1) {
            next()
          } else {
            next({ path: '/' })
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
          store.state.loading = false
        } catch (error) {
          next({ path: '/' })
          store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        }
      } else {
        next()
      }
    } else {
      if (JSON.parse(localStorage.getItem('token'))) {
        store.dispatch('show_snackbar', {
          text: 'La sesión actual expiró',
          color: 'error'
        })
      }
      await store.dispatch('logout')
      next({ path: '/login' })
    }
  } else {
    next()
  }
  window.scrollTo(0, 0)
})

function is_auth() {
  const token = JSON.parse(localStorage.getItem('token'))
  return !jwt.isExpired(token)
}

export default router
