import { API_URL } from '../../../config'
import store from '../../store'

const validarPayload = payload => {
  return payload || null
}

export default {
  namespaced: true,
  actions: {
    async buscar({}, payload) {
      const params = new URLSearchParams({
        ...payload
      })

      const response = await fetch(
        `${API_URL}/comisionesVN/buscar?${params.toString()}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          }
        }
      )

      return response.json()
    },

    async validarPeriodo({}, payload) {
      const params = new URLSearchParams({
        ...payload
      })
      const response = await fetch(
        `${API_URL}/comisionesVN/validarPeriodo?${params.toString()}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'Appliaction/json',
            Authorization: store.state.token
          }
        }
      )
      return response.json()
    },

    async validarRango({}, payload) {
      const params = new URLSearchParams({
        ...payload
      })
      const response = await fetch(
        `${API_URL}/comisionesVN/validarRango?${params.toString()}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          }
        }
      )
      return response.json()
    },

    async getComisiones({}, payload) {
      const response = await fetch(`${API_URL}/comisionesVN/comisiones`, {
        method: 'GET',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        }
      })

      return response.json()
    },

    async getVendedoresSalon() {
      const response = await fetch(`${API_URL}/comisionesVN/vendedores`, {
        method: 'GET',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        }
      })
      return response.json()
    },

    async getConceptos() {
      const response = await fetch(`${API_URL}/comisionesVN/conceptos`, {
        method: 'GET',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        }
      })

      return response.json()
    },

    async generarLiquidacion({}, payload) {
      const response = await fetch(`${API_URL}/comisionesVN/generar`, {
        method: 'POST',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        },
        body: JSON.stringify(payload)
      })

      return response.json()
    },

    async validarPreventa({}, payload) {
      const response = await fetch(`${API_URL}/comisionesVN/validarPreventa`, {
        method: 'POST',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        },
        body: JSON.stringify(payload)
      })
      return response.json()
    },

    async getPorcentajes() {
      const response = await fetch(`${API_URL}/comisionesVN/porcentajes`, {
        method: 'GET',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        }
      })
      return response.json()
    },

    async agregarDetalle({}, payload) {
      const response = await fetch(`${API_URL}/comisionesVN/agregar`, {
        method: 'POST',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        },
        body: JSON.stringify(payload)
      })
      return response.json()
    },

    async eliminarLiquidacion({}, payload) {
      const response = await fetch(
        `${API_URL}/comisionesVN/eliminarLiquidacion`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          },
          body: JSON.stringify(payload)
        }
      )
      return response.json()
    },

    async getVendedoresSinComision({}, payload) {
      const response = await fetch(
        `${API_URL}/comisionesVN/vendedoresSinComision`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          },
          body: JSON.stringify(payload)
        }
      )
      return response.json()
    },

    async eliminarDetalle({}, payload) {
      const response = await fetch(`${API_URL}/comisionesVN/eliminarDetalle`, {
        method: 'POST',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        },
        body: JSON.stringify(payload)
      })
      return response.json()
    },

    async getDatosPreventa({}, payload) {
      const params = new URLSearchParams({
        ...payload
      })

      const response = await fetch(
        `${API_URL}/comisionesVN/datosPreventa?${params.toString()}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          }
        }
      )

      return response.json()
    },

    async getLiquidacionXPeriodo({}, payload) {
      const response = await fetch(
        `${API_URL}/comisionesVN/liquidacionxperiodo`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          },
          body: JSON.stringify(payload)
        }
      )

      return response.json()
    },

    async getComisionCalculada({}, payload) {
      const response = await fetch(`${API_URL}/comisionesVN/calcularComision`, {
        method: 'POST',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        },
        body: JSON.stringify(payload)
      })
      return response.json()
    },

    async editarDetalle({}, payload) {
      const response = await fetch(`${API_URL}/comisionesVN/editarDetalle`, {
        method: 'POST',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        },
        body: JSON.stringify(payload)
      })
      return response.json()
    }
  }
}
