import { API_URL } from '../../../config'
import store from '../../store'

export default {
  namespaced: true,
  actions: {
    async subirEncabezados({}, payload) {
      const response = await fetch(
        `${API_URL}/retencionesPercepciones/encabezado`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          },
          body: JSON.stringify(payload)
        }
      )

      return response.json()
    },

    async subirSobrantes({}, payload) {
      const response = await fetch(
        `${API_URL}/retencionesPercepciones/sobrantes`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          },
          body: JSON.stringify(payload)
        }
      )

      return response.json()
    },

    async subirRetenciones({}, payload) {
      const response = await fetch(
        `${API_URL}/retencionesPercepciones/retenciones`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          },
          body: JSON.stringify(payload)
        }
      )

      return response.json()
    },

    async subirPercepciones({}, payload) {
      const response = await fetch(
        `${API_URL}/retencionesPercepciones/percepciones`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          },
          body: JSON.stringify(payload)
        }
      )

      return response.json()
    },

    async getRetenciones({}, payload) {
      const params = new URLSearchParams({
        fecha_desde: payload.fecha_desde || null
      })

      const respose = await fetch(
        `${API_URL}/retencionesPercepciones/retenciones?${params.toString()}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          }
        }
      )

      return respose.json()
    },

    async getPercepciones({}, payload) {
      const params = new URLSearchParams({
        fecha_desde: payload.fecha_desde || null
      })

      const respose = await fetch(
        `${API_URL}/retencionesPercepciones/percepciones?${params.toString()}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          }
        }
      )

      return respose.json()
    },

    async getCoincidencias({}, payload) {
      const params = new URLSearchParams({
        idCoincidencias: payload
      })

      const response = await fetch(
        `${API_URL}/retencionesPercepciones/coincidencias?${params.toString()}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          }
        }
      )

      return response.json()
    },

    async asignarCoincidencia({}, payload) {
      const response = await fetch(
        `${API_URL}/retencionesPercepciones/asignarCoincidencia`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          },
          body: JSON.stringify(payload)
        }
      )

      return response.json()
    },

    async desasignar({}, payload) {
      const response = await fetch(
        `${API_URL}/retencionesPercepciones/desasignar`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          },
          body: JSON.stringify(payload)
        }
      )

      return response.json()
    },

    async reprocesar({}, payload) {
      const response = await fetch(
        `${API_URL}/retencionesPercepciones/reprocesar`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          },
          body: JSON.stringify(payload)
        }
      )

      return response.json()
    },

    async reprocesarMasivo({}, payload) {
      const response = await fetch(
        `${API_URL}/retencionesPercepciones/reprocesarMasivo`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          },
          body: JSON.stringify(payload)
        }
      )
      return response.json()
    },
    async getEncabezados({}, payload) {
      const params = new URLSearchParams({
        idsEncabezados: payload.idsEncabezados || null,
        tipo: payload.tipo || null,
        fecha: payload.fecha || null
      })

      const response = await fetch(
        `${API_URL}/retencionesPercepciones/encabezados?${params.toString()}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          }
        }
      )

      return response.json()
    },

    async eliminarEncabezado({}, payload) {
      const response = await fetch(
        `${API_URL}/retencionesPercepciones/eliminarEncabezado`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          },
          body: JSON.stringify(payload)
        }
      )

      return response.json()
    }
  }
}
