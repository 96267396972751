import config from '../../../config'
import store from '../../store'
import { order_list_by } from '../../util/utils'

export default {
  namespaced: true,
  state: {
    estados_mant: [],
    usuarios_asignar: [],
    motivos_np: [],
    p_pagar_cuota: false,
    p_descargar_excel: false
  },
  mutations: {
    set_estados (state, payload) {
      order_list_by(payload, 'nombre')
      state.estados_mant = payload
    },
    set_usuarios (state, payload) {
      order_list_by(payload, 'nombre')
      state.usuarios_asignar = payload
    },
    set_permiso_pagar_cuota (state, payload) {
      state.p_pagar_cuota = payload
    },
    set_permiso_descargar_excel (state, payload) {
      state.p_descargar_excel = payload
    },
    set_motivos (state, payload) {
      order_list_by(payload, 'nombre')
      state.motivos_np = payload
    }
  },
  actions: {
    async get_estados ({ state, commit }) {
      try {
        if (state.estados_mant.length == 0) {
          const res = await fetch(`${config.API_URL}/planes/mantenimiento/estados`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            commit('set_estados', data.data)
          } else {
            commit('set_estados', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
        }
      } catch (error) {
        commit('set_estados', [])
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
    },
    async get_usuarios ({ state, commit }) {
      try {
        if (state.usuarios_asignar.length == 0) {
          const res = await fetch(`${config.API_URL}/planes/mantenimiento/usuarios`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            commit('set_usuarios', data.data)
          } else {
            commit('set_usuarios', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
        }
      } catch (error) {
        commit('set_usuarios', [])
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
    },
    async get_cartera ({}, fto) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.API_URL}/planes/mantenimiento/search?familia=${fto.familia}&empresa=${fto.empresa}&avance=${fto.avance}&avance_desde=${fto.avance_desde}&avance_hasta=${fto.avance_hasta}&preimpreso=${fto.preimpreso}&grupo=${fto.grupo}&orden=${fto.orden}&estado=${fto.estado}&usuario=${fto.usuario}&fp=${fto.fp}&propio=${fto.propio}&alerta=${fto.alerta}&contactar=${fto.contactar}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })
    
          const data = await res.json()
    
          resolve(data)
    
        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async get_excel ({}, fto) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.API_URL}/planes/mantenimiento/excel?familia=${fto.familia}&empresa=${fto.empresa}&avance=${fto.avance}&avance_desde=${fto.avance_desde}&avance_hasta=${fto.avance_hasta}&preimpreso=${fto.preimpreso}&grupo=${fto.grupo}&orden=${fto.orden}&estado=${fto.estado}&usuario=${fto.usuario}&fp=${fto.fp}&propio=${fto.propio}&alerta=${fto.alerta}&contactar=${fto.contactar}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })
    
          const data = await res.json()
    
          resolve(data)
    
        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async get_usuario () {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.API_URL}/planes/mantenimiento/usuario`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })
    
          const data = await res.json()
    
          resolve(data)
    
        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async editar_detalle ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.API_URL}/planes/mantenimiento/${payload.cartera_id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            },
            body: JSON.stringify(payload)
          })
    
          const data = await res.json()
    
          resolve(data)
    
        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async get_pagos ({}, id) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.API_URL}/planes/mantenimiento/${id}/pagos`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })
    
          const data = await res.json()
    
          resolve(data)
    
        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async nuevo_pago ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.API_URL}/planes/mantenimiento/${payload.id}/pago`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            },
            body: JSON.stringify({
              cuotas: payload.cuotas,
              monto: payload.monto,
              observaciones: payload.observaciones,
              periodo: payload.periodo
            })
          })
    
          const data = await res.json()
    
          resolve(data)
    
        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async get_permiso_pagar_cuota ({ commit }) {
      try {
        const res = await fetch(`${config.API_URL}/planes/mantenimiento/permisoPagarCuota`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': store.state.token
          }
        })
    
        const data = await res.json()

        if (data.exito == 1) {
          commit('set_permiso_pagar_cuota', data.permiso == 1 ? true : false)
        } else {
          commit('set_permiso_pagar_cuota', false)
          store.dispatch('show_snackbar', {
            text: data.message,
            color: 'error'
          })
        }
    
      } catch (error) {
        commit('set_permiso_pagar_cuota', false)
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
    },
    async get_permiso_descargar_excel ({ commit }) {
      try {
        const res = await fetch(`${config.API_URL}/planes/mantenimiento/permisoDescargarExcel`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': store.state.token
          }
        })
    
        const data = await res.json()

        if (data.exito == 1) {
          commit('set_permiso_descargar_excel', data.permiso == 1 ? true : false)
        } else {
          commit('set_permiso_descargar_excel', false)
          store.dispatch('show_snackbar', {
            text: data.message,
            color: 'error'
          })
        }
    
      } catch (error) {
        commit('set_permiso_descargar_excel', false)
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
    },
    async asignar ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.API_URL}/planes/mantenimiento/${payload.cartera_id}/asignar`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            },
            body: JSON.stringify(payload)
          })
    
          const data = await res.json()
    
          resolve(data)
    
        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async asignar_masivo ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.API_URL}/planes/mantenimiento/asignar`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            },
            body: JSON.stringify(payload)
          })
    
          const data = await res.json()
    
          resolve(data)
    
        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async get_motivos ({ state, commit }) {
      try {
        if (state.motivos_np.length == 0) {
          const res = await fetch(`${config.API_URL}/planes/mantenimiento/motivos`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            commit('set_motivos', data.data)
          } else {
            commit('set_motivos', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
        }
      } catch (error) {
        commit('set_motivos', [])
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
    },
    async get_historial ({}, fto) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.API_URL}/planes/mantenimiento/historial?preimpreso=${fto.preimpreso}&empresa=${fto.empresa}&solo_usuario=${fto.solo_usuario}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })
    
          const data = await res.json()
    
          resolve(data)
    
        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async get_comentarios ({}, id) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.API_URL}/planes/mantenimiento/${id}/comentarios`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })
    
          const data = await res.json()
    
          resolve(data)
    
        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },

    async subirPagosMasivo({}, payload){
      const response = await fetch(`${config.API_URL}/planes/mantenimiento/pagosMasivo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        },
        body: JSON.stringify(payload)
      })

      return response.json()
    }
  }
}