import { NumerosALetras } from 'numero-a-letras'
import moment from 'moment'

// TODOS LOS TIPOS DE ARCHIVO QUE ACEPTA dycar-web
export const tipos_archivos = [
  // pdf
  { extension: '.pdf', mime: 'application/pdf', icono: 'far fa-file-pdf', color: 'red darken-4', tipo: 'PDF' },
  // imagenes
  { extension: '.png', mime: 'image/png', icono: 'far fa-image', color: 'light-blue darken-1', tipo: 'IMG' },
  { extension: '.jpg', mime: 'image/jpeg', icono: 'far fa-image', color: 'light-blue darken-1', tipo: 'IMG' },
  { extension: '.jpeg', mime: 'image/jpeg', icono: 'far fa-image', color: 'light-blue darken-1', tipo: 'IMG' },
  { extension: '.jfif', mime: 'image/jpeg', icono: 'far fa-image', color: 'light-blue darken-1', tipo: 'IMG' },
  { extension: '.webp', mime: 'image/webp', icono: 'far fa-image', color: 'light-blue darken-1', tipo: 'IMG' },
  // documentos de texto
  { extension: '.txt', mime: 'text/plain', icono: 'far fa-file-alt', color: '', tipo: 'TXT' },
  { extension: '.doc', mime: 'application/msword', icono: 'far fa-file-word', color: 'blue darken-4', tipo: 'DOC' },
  { extension: '.docx', mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', icono: 'far fa-file-word', color: 'blue darken-4', tipo: 'DOC' },
  { extension: '.odt', mime: 'application/vnd.oasis.opendocument.text', icono: 'far fa-file-word', color: 'blue darken-4', tipo: 'DOC' },
  // hojas de cálculo
  { extension: '.csv', mime: 'text/csv', icono: 'fas fa-file-csv', color: 'green darken-4', tipo: 'CSV' },
  { extension: '.xls', mime: 'application/vnd.ms-excel', icono: 'far fa-file-excel', color: 'green darken-4', tipo: 'XLS' },
  { extension: '.xlsx', mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', icono: 'far fa-file-excel', color: 'green darken-4', tipo: 'XLS' },
  { extension: '.ods', mime: 'application/vnd.oasis.opendocument.spreadsheet', icono: 'far fa-file-excel', color: 'green darken-4', tipo: 'XLS' },
  // video
  { extension: '.mp4', mime: 'video/mp4', icono: 'fas fa-film', color: 'amber darken-1', tipo: 'VID' },
  { extension: '.avi', mime: 'video/x-msvideo', icono: 'fas fa-film', color: 'amber darken-1', tipo: 'VID' },
  { extension: '.mpg', mime: 'video/mpeg', icono: 'fas fa-film', color: 'amber darken-1', tipo: 'VID' },
  { extension: '.webm', mime: 'video/webm', icono: 'fas fa-film', color: 'amber darken-1', tipo: 'VID' }
]

// ordena una lista de onjetos segun el atributo (key) especificado
// se pude elegir entre desc y asc (con orden), si no se indica alguno por defecto ordena desc
export function order_list_by (list, key, orden) {
  let desc = -1
  let asc = 1
  if (orden == 'asc') {
    desc = 1
    asc = -1
  }
  list.sort(function(a, b) {
    const x = typeof a[key] == 'string' ? a[key].toLowerCase() : a[key]
    const y = typeof b[key] == 'string' ? b[key].toLowerCase() : b[key]
    if(x < y) { return desc }
    if(x > y) { return asc }
    return 0
  })
}

// money debe ser de tipo numérico o flotante
export function format_money (money) {
  if (!money && money != 0) return ''
  return new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(money)
}

export function format_money_round (money) {
  if (!money && money != 0) return ''
  return new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS', maximumFractionDigits: 0 }).format(money)
}

// el formato de la fecha debe ser DD MM YYYY (en el orden que se desee) separados por - o /
// si no se especifica el formato, se convierte a DD/MM/YYYY
export function format_date (date, format) {
  if (!date) return ''
  return moment(date).format(format ? format : 'DD/MM/YYYY')
}


// convierte una fecha en formato DD/MM/YYYY a YYYY-MM-DD
export function parseDate (date) {
  if (!date) return null
  const [day, month, year] = date.split('/')
  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
}

// si un valor es null o indefinido lo convierte a ''
export function format_str (value) {
  if (!value && value != 0) return ''
  return value.toString()
}

export function NumeroALetra (valor) {
  let numero = NumerosALetras(valor)
  numero = numero.replace('Pesos', 'c/')
  numero = numero.replace('de ', '')
  numero = numero.replace('M.N.', 'CTS')
  return numero
}

// convierte un archivo en base64
export function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

// devuelve un objeto con el ancho y alto de una imagen en base64
export async function getDims (base64) {
  return await new Promise((resolve) => {
    let img = new Image()
    img.src = base64
    img.onload = () => {
      resolve({
        ancho: img.width,
        alto: img.height
      })
    }
  })
}

// formatea una fehca de tipo YYYY-MM-DD a Mes/YY
export function formatMesAño (fecha) {
  if (!fecha) return ''
  return moment(fecha).locale('es-AR').format('MMMM').substring(0,1).toUpperCase() + moment(fecha).locale('es-AR').format('MMMM').substring(1,3) + moment(fecha).format('/YY')
}

/**
 * Esta funcion sirve para obtener el nombre de un item dentro de un array buscando por una key
 * 
 *  Ej: si se quiere obtener el nombre de una empresa mediante su id se debe indicar el array de emrpesas
 *      almacenado en el local storage y la key por la que va a buscar la empresa, en este caso el id. La
 *      key debe ser única como un codigo/id para obtener el nombre correcto. Ejemplos de uso:
 * 
 *      const nombre_empresa = getNombreObjArray(this.empresas, 'id', this.vendedor.empresa_id)
 *      const nombre_categoria = getNombreObjArray(this.categorias, 'codigo', this.articulo.categoria_codigo)
 * 
 *  Notas: - si no encuentra ninguna coincidencia devuelve ''
 *         - atributo se utiliza cunando el nombre que se quiere obtener no se llama nombre. Por ejemplo si se
 *           quiere el nombre corto de una empresa y no su nombre completo seria:
 * 
 *           const emp_abreviacion = getNombreObjArray(this.empresas, 'id', this.vendedor.empresa_id, 'nombre_corto')
 * 
 */
export function getNombreObjArray (array, key, value, atributo) {
  const item = array.find(i => i[key] == value)
  if (item) {
    if (atributo) {
      return item[atributo]
    } else {
      return item.nombre
    }
  } else {
    return ''
  }
}

export function convertDecimals(numero){
  return parseFloat(parseFloat(numero.toString().replace(',', '.')).toFixed(2).toString().replace(',', '.'))
}

export function convertDecimalsPlus(numero){
  return parseFloat(parseFloat(parseFloat(parseFloat(numero.toString().replace(',', '.')).toFixed(4).toString().replace(',', '.')).toFixed(3).toString().replace(',', '.')).toFixed(2).toString().replace(',', '.'))
}

export function rellenarConCeros(numero, longitud) {
  return numero.toString().padStart(longitud, '0');
}