import config from "../../../config";
import store from "../../store";

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async get_incentivos({}, fto) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(
            `${config.API_URL}/planes/dashboard/incentivos?periodo=${fto.periodo}&marca=${fto.marca}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: store.state.token,
              },
            }
          );

          const data = await res.json();

          resolve(data);
        } catch (error) {
          resolve({
            exito: 0,
            message: error,
          });
        }
      });
    },

    async get_objetivos({}, filtro) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: store.state.token,
      };

      try {
        const res = await fetch(
          `${config.API_URL}/planes/dashboard/tolerancias/${filtro.marca}`,
          {
            method: "GET",
            headers,
          }
        );

        return await res.json();
      } catch (error) {
        return { exito: 0, message: error };
      }
    },

    async post_objetivos({}, { filtro, payload }) {
      // api/planes/dashboard/tolerancias/{num_marca}

      const headers = {
        "Content-Type": "application/json",
        Authorization: store.state.token,
      };

      const body = { ...payload };

      try {
        const res = await fetch(
          `${config.API_URL}/planes/dashboard/tolerancias/${filtro.marca}`,
          {
            method: "POST",
            headers,
            body: JSON.stringify(body),
          }
        );

        return await res.json();
      } catch (error) {
        return { exito: 0, message: error };
      }
    },
  },
};
