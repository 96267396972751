import { API_URL } from '../../../config'
import store from '../../store'

export default {
  namespaced: true,
  actions: {

    async getPermisos() {
      const response = await fetch(`${API_URL}/formularios/permisos`, {
        method: 'GET',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        }
      })

      return response.json()
    },


    async getFormularios({}, payload) {
      const params = new URLSearchParams({
        ...payload
      })

      const response = await fetch(
        `${API_URL}/formularios?${params.toString()}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          }
        }
      )

      return response.json()
    },

    async getEstados() {
      const response = await fetch(`${API_URL}/formularios/estados`, {
        method: 'GET',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        }
      })

      return response.json()
    },

    async validarNumero({}, payload) {
      const response = await fetch(`${API_URL}/formularios/validarNumero`, {
        method: 'POST',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        },
        body: JSON.stringify(payload)
      })

      return response.json()
    },

    async getSucursal({}, payload) {
      const response = await fetch(`${API_URL}/formularios/sucursal`, {
        method: 'POST',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        },
        body: JSON.stringify(payload)
      })

      return response.json()
    },

    async getUsuarios({}, payload) {
      const response = await fetch(`${API_URL}/formularios/usuarios`, {
        method: 'POST',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        },
        body: JSON.stringify(payload)
      })

      return response.json()
    },

    async asignarFormulario({}, payload) {
      const response = await fetch(`${API_URL}/formularios/asignar`, {
        method: 'POST',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        },
        body: JSON.stringify(payload)
      })

      return response.json()
    },

    async getFormularioPorNumero({}, payload) {
      const response = await fetch(`${API_URL}/formularios/formulario`, {
        method: 'POST',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        },
        body: JSON.stringify(payload)
      })

      return response.json()
    },

    async anularFormulario({}, payload) {
      const response = await fetch(`${API_URL}/formularios/anular`, {
        method: 'POST',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        },
        body: JSON.stringify(payload)
      })

      return response.json()
    },

    async getImagenesFormulario({}, payload) {
      const params = new URLSearchParams({
        ...payload
      })

      const response = await fetch(
        `${API_URL}/formularios/imagenes?${params.toString()}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          }
        }
      )

      return response.json()
    },

    async eliminarArchivo({}, payload) {
      const response = await fetch(`${API_URL}/formularios/eliminarArchivo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        },
        body: JSON.stringify(payload)
      })

      return response.json()
    },

    async agregarImagenes({}, payload) {
      const response = await fetch(`${API_URL}/formularios/agregarImagenes`, {
        method: 'POST',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        },
        body: JSON.stringify(payload)
      })

      return response.json()
    },

    async getComentarios({}, payload) {
      const params = new URLSearchParams({
        ...payload
      })

      const response = await fetch(
        `${API_URL}/formularios/comentarios?${params.toString()}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          }
        }
      )

      return response.json()
    },

    async setPendiente({}, payload){
      const response = await fetch(`${API_URL}/formularios/pendienteValidar`, {
        method: 'POST',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        },
        body: JSON.stringify(payload)
      })

      return response.json()
    },

    async setAsignado({}, payload){
      const response = await fetch(`${API_URL}/formularios/asignado`, {
        method: 'POST',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        },
        body: JSON.stringify(payload)
      })

      return response.json()
    },

    async setValidado({}, payload){
      const response = await fetch(`${API_URL}/formularios/validado`, {
        method: 'POST',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        },
        body: JSON.stringify(payload)
      })

      return response.json()
    },
  }
}
