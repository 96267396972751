<template>
  <v-dialog
    v-model="dialog"
    width="400"
    :persistent="load"
  >
    <v-card :disabled="load" :loading="load && mecanico == null">
      <v-card-title>
        Cambiar PIN
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="body-1 pt-4">
        <span class="px-4">Mecánico</span>
        <v-autocomplete
          v-model="mecanico"
          ref="mecanicoSelect"
          class="px-4"
          item-value="id"
          item-text="nombre"
          :items="mecanicos"
          hide-details
          dense
          outlined
          @change="limpiarPIN()"
        ></v-autocomplete>
        <v-form v-if="mecanico != null" class="ma-4">
          PIN actual
          <v-otp-input
            v-model="pin_old"
            ref="pinOld"
            length="6"
            type="password"
            @change="validarOld()"
            @finish="validarOld(1)"
          ></v-otp-input>
          PIN nuevo
          <v-otp-input
            v-model="pin_new"
            ref="pinNew"
            length="6"
            type="password"
            :disabled="disabledNew"
            @change="validarNew()"
            @finish="validarNew(1)"
          ></v-otp-input>
          Repita su PIN nuevo
          <v-otp-input
            v-model="pin_repeat"
            ref="pinRepeat"
            length="6"
            type="password"
            :disabled="disabledRepeat"
            @change="validarRepeat()"
            @finish="validarRepeat(1)"
          ></v-otp-input>
          <v-btn
            color="primary"
            class="mt-8"
            :disabled="disabledConfirm"
            :loading="load"
            block
            @click="cambiar_pin()"
          >
            Confirmar
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { Encrypt } from '../util/aes'

export default {
  data () {
    return {
      load: false,
      disabledNew: true,
      disabledRepeat: true,
      disabledConfirm: true,
      mecanico: null,
      pin_new: '',
      pin_old: '',
      pin_repeat: ''
    }
  },
  props: {
    value: Boolean
  },
  watch: {
    async dialog (val) {
      if (val) {
        this.load = true
        await this.$store.dispatch('taller/get_mecanicos')
        this.load = false
      } else {
        this.mecanico = null
        this.limpiar()
      }
    }
  },
  computed: {
    ...mapState('taller', ['mecanicos']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    async cambiar_pin () {
      this.load = true
      let result = await this.$store.dispatch('taller/cambiar_pin', {
        mecanico: this.mecanico,
        actual: Encrypt(this.pin_old),
        nuevo: Encrypt(this.pin_new)
      })
      this.load = false

      if (result.exito == 1) {
        this.dialog = false
        this.$swal.fire({
          icon: 'success',
          title: 'PIN actualizado correctamente'
        })
      } else {
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'error'
        })
      }
    },
    async validarOld (finish) {
      this.disabledConfirm = true
      if (this.pin_old.length == 6) {
        this.disabledNew = false
        if (finish == 1) {
          await new Promise(resolve => setTimeout(resolve, 1))
          this.$refs.pinNew.focus()
        }
      } else {
        this.disabledNew = true
        this.disabledRepeat = true
        this.pin_new = ''
        this.pin_repeat = ''
      }
    },
    async validarNew (finish) {
      this.disabledConfirm = true
      if (this.pin_new.length == 6) {
        this.disabledRepeat = false
        if (finish == 1) {
          if (this.pin_new == this.pin_old) {
            this.$store.dispatch('show_snackbar', {
              text: 'Ingrese un pin diferente al anterior',
              color: 'orange'
            })
            this.pin_new = ''
            this.$refs.pinNew.focus()
            return
          }
          await new Promise(resolve => setTimeout(resolve, 1))
          this.$refs.pinRepeat.focus()
        }
      } else {
        this.disabledRepeat = true
        this.pin_repeat = ''
      }
    },
    validarRepeat (finish) {
      if (this.pin_repeat.length == 6) {
        if (finish == 1) {
          if (this.pin_repeat != this.pin_new) {
            this.$store.dispatch('show_snackbar', {
              text: 'Los pines no coinciden',
              color: 'orange'
            })
            this.pin_repeat = ''
            this.$refs.pinRepeat.focus()
            return
          }
        }
        this.disabledConfirm = false
      } else {
        this.disabledConfirm = true
      }
    },
    async limpiarPIN () {
      this.$refs.mecanicoSelect.blur()
      await new Promise(resolve => setTimeout(resolve, 1))
      this.$refs.pinOld.focus()
      this.limpiar()
    },
    limpiar () {
      this.disabledNew = true
      this.disabledRepeat = true
      this.disabledConfirm = true
      this.pin_new = ''
      this.pin_old = ''
      this.pin_repeat = ''
    }
  }
}
</script>