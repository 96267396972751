import config from '../../../config'
import store from '../../store'

export default {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  actions: {
    async eliminar ({}, id) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.API_URL}/rutas/${id}`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async nueva ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.API_URL}/rutas`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async editar ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.API_URL}/rutas/${payload.id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async editar_roles ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.API_URL}/rutas/${payload.id}/roles`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_roles ({}, id) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.API_URL}/rutas/${id}/roles`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_rutas ({}, filtro) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.API_URL}/rutas/search?nombre=${filtro.nombre}&descripcion=${filtro.descripcion}&rol=${filtro.rol}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    }
  }
}