import config from '../../../config'
import store from '../../store'
import { order_list_by } from '../../util/utils'

export default {
  namespaced: true,
  state: {
    vendedores: [],
    p_descargar: false
  },
  mutations: {
    set_vendedores (state, payload) {
      order_list_by(payload, 'nombre')
      state.vendedores = payload
    },
    set_permiso_descargar (state, payload) {
      state.p_descargar = payload
    }
  },
  actions: {
    async get_vendedores ({ state, commit }) {
      try {
        if (state.vendedores.length == 0) {
          const res = await fetch(`${config.API_URL}/planes/ventas/vendedores`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            commit('set_vendedores', data.data)
          } else {
            commit('set_vendedores', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
        }
      } catch (error) {
        commit('set_vendedores', [])
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
    },
    async verificar_usuario_vendedor () {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.API_URL}/planes/ventas/verificarUsuarioVendedor`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })
    
          const data = await res.json()
    
          resolve(data)
    
        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async get_ventas ({}, fto) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.API_URL}/planes/ventas/search?familia=${fto.familia}&empresa=${fto.empresa}&avance=${fto.avance}&avance_desde=${fto.avance_desde}&avance_hasta=${fto.avance_hasta}&preimpreso=${fto.preimpreso}&grupo=${fto.grupo}&orden=${fto.orden}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })
    
          const data = await res.json()
    
          resolve(data)
    
        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async nueva_venta ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.API_URL}/planes/ventas`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            },
            body: JSON.stringify(payload)
          })
    
          const data = await res.json()
    
          resolve(data)
    
        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async get_excel ({}, periodo) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.API_URL}/planes/ventas/excel?periodo=${periodo}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })

          const data = await res.json()

          resolve(data)

        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async get_permiso_descargar ({ commit }) {
      try {
        const res = await fetch(`${config.API_URL}/planes/ventas/permisoDescargar`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': store.state.token
          }
        })
    
        const data = await res.json()

        if (data.exito == 1) {
          commit('set_permiso_descargar', data.permiso == 1 ? true : false)
        } else {
          commit('set_permiso_descargar', false)
          store.dispatch('show_snackbar', {
            text: data.message,
            color: 'error'
          })
        }
    
      } catch (error) {
        commit('set_permiso_descargar', false)
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
    }
  }
}