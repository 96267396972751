import config from '../../../config'
import store from '../../store'
import { order_list_by } from '../../util/utils'

export default {
  namespaced: true,
  state: {
    estados_recupero: [],
    situaciones: [],
    usuarios_asignar: [],
    p_editar: false
  },
  mutations: {
    set_estados (state, payload) {
      order_list_by(payload, 'nombre')
      state.estados_recupero = payload
    },
    set_situaciones (state, payload) {
      order_list_by(payload, 'text')
      state.situaciones = payload
    },
    set_usuarios (state, payload) {
      order_list_by(payload, 'nombre')
      state.usuarios_asignar = payload
    },
    set_permiso_editar (state, payload) {
      state.p_editar = payload
    }
  },
  actions: {
    async get_estados ({ state, commit }) {
      try {
        if (state.estados_recupero.length == 0) {
          const res = await fetch(`${config.API_URL}/planes/recuperos/estados`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            commit('set_estados', data.data)
          } else {
            commit('set_estados', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
        }
      } catch (error) {
        commit('set_estados', [])
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
    },
    async get_situaciones ({ state, commit }) {
      try {
        if (state.situaciones.length == 0) {
          const res = await fetch(`${config.API_URL}/planes/recuperos/situaciones`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            commit('set_situaciones', data.data)
          } else {
            commit('set_situaciones', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
        }
      } catch (error) {
        commit('set_situaciones', [])
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
    },
    async get_usuarios ({ state, commit }) {
      try {
        if (state.usuarios_asignar.length == 0) {
          const res = await fetch(`${config.API_URL}/planes/recuperos/usuarios`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            commit('set_usuarios', data.data)
          } else {
            commit('set_usuarios', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
        }
      } catch (error) {
        commit('set_usuarios', [])
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
    },
    async get_recuperos ({}, fto) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.API_URL}/planes/recuperos/search?familia=${fto.familia}&empresa=${fto.empresa}&avance=${fto.avance}&avance_desde=${fto.avance_desde}&avance_hasta=${fto.avance_hasta}&preimpreso=${fto.preimpreso}&grupo=${fto.grupo}&orden=${fto.orden}&estado=${fto.estado}&situacion=${fto.situacion}&usuario=${fto.usuario}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })
    
          const data = await res.json()
    
          resolve(data)
    
        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async nuevo_recupero ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.API_URL}/planes/recuperos`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            },
            body: JSON.stringify(payload)
          })
    
          const data = await res.json()
    
          resolve(data)
    
        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async editar_recupero ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.API_URL}/planes/recuperos/${payload.recupero_id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            },
            body: JSON.stringify(payload)
          })
    
          const data = await res.json()
    
          resolve(data)
    
        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async get_permiso_editar ({ commit }) {
      try {
        const res = await fetch(`${config.API_URL}/planes/recuperos/permisoEditar`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': store.state.token
          }
        })
    
        const data = await res.json()

        if (data.exito == 1) {
          commit('set_permiso_editar', data.permiso == 1 ? true : false)
        } else {
          commit('set_permiso_editar', false)
          store.dispatch('show_snackbar', {
            text: data.message,
            color: 'error'
          })
        }
    
      } catch (error) {
        commit('set_permiso_editar', false)
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
    },
    async get_usuario () {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.API_URL}/planes/recuperos/usuario`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })
    
          const data = await res.json()
    
          resolve(data)
    
        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async volver_contactar ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.API_URL}/planes/recuperos/${payload.cartera_id}/contactar`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            },
            body: JSON.stringify(payload)
          })
    
          const data = await res.json()
    
          resolve(data)
    
        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    }
  }
}