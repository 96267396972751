import config from "../../config";
import store from "../store";

import { VueEasyJwt } from "vue-easy-jwt";

const jwt = new VueEasyJwt();
let jarvis_token = window.localStorage.getItem("jarvis_token");

export default {
  namespaced: true,
  state: {
    formas_cobro: [],
    formas_cobro_cod: [],
  },

  mutations: {
    set_formas_cobro(state, payload) {
      state.formas_cobro = payload;
      order_list_by(state.formas_cobro, "nombre");
      localStorage.setItem("formas_cobro", JSON.stringify(payload));
    },
    set_formas_cobro_cod(state, payload) {
      state.formas_cobro_cod = payload;
      order_list_by(state.formas_cobro_cod, "nombre");
    },
  },
  actions: {
    async init_cobros({}, payload) {
      try {
        if (jarvis_token == null || jwt.isExpired(jarvis_token)) {
          await store.dispatch("get_jarvis_token");
          jarvis_token = window.localStorage.getItem("jarvis_token");
        }

        const opPeticion = await fetch(
          `${config.API_JARVIS}/cobros/initFormCobros?nuevo=${payload.nuevo}&id=${payload.id}&tipo_pedido=${payload.tipo_pedido}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: jarvis_token,
            },
          }
        );
        let operaciones = await opPeticion.json();
        operaciones.msj = operaciones.message;

        return operaciones;
      } catch (error) {
        return {
          resultado: 0,
          msj:
            "Ocurrio un problema al ejecutar el metodo init_cobros: " +
            error.message,
          operaciones: null,
        };
      }
    },

    async get_cobros_online({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          let cobrosPeticion = await fetch(
            `${config.API_JARVIS}/cobros/getCobrosOnline?empresa=${payload.empresa}&sucursal=${payload.sucursal}&pto_vta=${payload.pto_vta}&operacion=${payload.operacion}&estado=${payload.estado}&fecha_desde=${payload.fecha_desde}&fecha_hasta=${payload.fecha_hasta}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: jarvis_token,
              },
            }
          );
          let cobros = await cobrosPeticion.json();
          cobros.msj = cobros.message;
          return resolve(cobros);
        } catch (error) {
          return resolve({
            resultado: 0,
            msj:
              "Ocurrio un problema al ejecutar el metodo get_cobros_online: " +
              error.message,
            cobros: null,
          });
        }
      });
    },

    async get_pdf_cobro_data({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          let dataPeticion = await fetch(
            `${config.API_JARVIS}/cobros/getPdfCobroData?id=${payload.id}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: jarvis_token,
              },
            }
          );
          let data = await dataPeticion.json();
          data.msj = data.message;
          return resolve(data);
        } catch (error) {
          return resolve({
            resultado: 0,
            msj:
              "Ocurrio un problema al ejecutar el metodo get_pdf_cobro_data: " +
              error.message,
            cobro: null,
          });
        }
      });
    },

    async check_cobro_sin_asociar({}, payload) {
      try {
        const checkPeticion = await fetch(
          `${config.API_JARVIS}/cobros/puedoAnularCobroSinAsociar?transaccion_id=${payload.transaccion_id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: jarvis_token,
            },
          }
        );

        const check = await checkPeticion.json();
        check.msj = check.message;
        return check;
      } catch (error) {
        return {
          resultado: 0,
          msj:
            "Ocurrió un problema al ejecutar el método checkCobroSinAsociar: " +
            error.message,
          cobro: null,
        };
      }
    },

    async anular_cobro_electronico({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          let anulacionPeticion = await fetch(
            `${config.API_JARVIS}/decidir/anular`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: jarvis_token,
              },
              body: JSON.stringify(payload),
            }
          );
          let anulacion = await anulacionPeticion.json();
          if (anulacion.exito == 1) {
            return resolve({
              resultado: 1,
              msj: "OK",
            });
          } else {
            return resolve({
              resultado: 0,
              msj: anulacion.mensaje,
            });
          }
        } catch (error) {
          return resolve({
            resultado: 0,
            msj:
              "Ocurrio un problema al ejecutar el metodo anular_cobro_electronico: " +
              error.message,
          });
        }
      });
    },

    async get_financiaciones_habilitadas({}, payload) {
      try {
        const fpPeticion = await fetch(
          `${config.API_JARVIS}/financiaciones/getFinanciacionesHabilitadas`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: jarvis_token,
            },
            body: JSON.stringify(payload),
          }
        );
        const fps = await fpPeticion.json();
        fps.msj = fps.message;
        return fps;
      } catch (error) {
        return {
          resultado: 0,
          msj:
            "Ocurrio un problema al ejecutar el metodo get_financiaciones_habilitadas: " +
            error.message,
          financiaciones: null,
        };
      }
    },

    async getPermisoCobro() {
      try {
        const permisoCobro = await fetch(`${config.API_URL}/permisoCobro`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: store.state.token,
          },
        });

        const getPermiso = await permisoCobro.json();
        getPermiso.msj = getPermiso.message;

        return getPermiso;
      } catch (error) {
        return {
          resultado: 0,
          msj:
            "Ocurrio un problema al ejecutar el metodo getPermisoCobro: " +
            error.message,
          financiaciones: null,
        };
      }
    },

    async process_payment({}, payload) {
      try {
        const cobroPeticion = await fetch(`${config.API_JARVIS}/decidir/Pago`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: jarvis_token,
          },
          body: JSON.stringify(payload),
        });
        let cobro = await cobroPeticion.json();
        return {
          resultado: 1,
          msj: "OK",
          datosCobro: cobro,
        };
      } catch (error) {
        return {
          resultado: 0,
          msj:
            "Ocurrio un problema al ejecutar el metodo process_payment: " +
            error.message,
          datosCobro: null,
        };
      }
    },
  },
};
