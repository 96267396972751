import { API_JARVIS, API_URL } from '../../../config'
import store from '../../store'

export default {
  namespaced: true,
  state: {
    vendedor: null,
    favorito: null,
    cargaInc: false,
    nombreUs: '',
    inc_tipos: [],
    inc_estados: [],
    solicitantes: [],
    personal_mesa: []
  },

  actions: {
    async getIncidentes({}, filtro) {
      const params = new URLSearchParams({ ...filtro }).toString()
      const response = await fetch(
        `${API_JARVIS}/incidentes/search-terceros?${params}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.jarvis_token
          }
        }
      )
      return response.json()
    },

    async getEstados() {
      const response = await fetch(`${API_JARVIS}/incidentes/estados`, {
        method: 'GET',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.jarvis_token
        }
      })

      return response.json()
    },

    async nuevoIncidente({}, payload) {
      const response = await fetch(`${API_JARVIS}/incidentes/nuevo-terceros`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: store.state.jarvis_token
        },
        body: JSON.stringify(payload)
      })

      return response.json()
    },

    async getPersonalId() {
      const response = await fetch(`${API_URL}/incidentes/personalid`, {
        method: 'GET',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        }
      })

      return response.json()
    },

    async comentario({}, payload) {
      const response = await fetch(
        `${API_JARVIS}/incidentes/comentario-terceros`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: store.state.jarvis_token
          },

          body: JSON.stringify(payload)
        }
      )

      return response.json()
    },
    async getComentarios({}, payload) {
      const params = new URLSearchParams({ ...payload }).toString()

      const response = await fetch(
        `${API_JARVIS}/incidentes/comentarios-terceros?${params}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: store.state.jarvis_token
          }
        }
      )

      return response.json()
    },

    async getArchivos({}, payload) {
      const params = new URLSearchParams({ ...payload }).toString()

      const response = await fetch(
        `${API_JARVIS}/incidentes/archivos-terceros?${params}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: store.state.jarvis_token
          }
        }
      )
      return response.json()
    },

    async getNombreUsuario({}, payload) {
      const response = await fetch(`${API_JARVIS}/incidentes/nombre-terceros`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: store.state.jarvis_token
        },
        body: JSON.stringify(payload)
      })
      return response.json()
    }
  }
}
