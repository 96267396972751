import { API_URL } from '../../../config'
import store from '../../store'

const validarPayload = payload => {
  return payload || null
}

export default {
  namespaced: true,
  actions: {
    async getPreventas({}, payload) {
      const params = new URLSearchParams({
        empresa: validarPayload(payload.empresa),
        sucursal: validarPayload(payload.sucursal),
        preventa: validarPayload(payload.preventa),
        vendedor: validarPayload(payload.vendedor),
        titular: validarPayload(payload.titular),
        fechaPreventa: validarPayload(payload.fechaPreventa),
        preventaDesde: validarPayload(payload.preventaDesde),
        preventaHasta: validarPayload(payload.preventaHasta),
        patentamientoDesde: validarPayload(payload.patentamientoDesde),
        patentamientoHasta: validarPayload(payload.patentamientoHasta),
        entregaDesde: validarPayload(payload.entregaDesde),
        entregaHasta: validarPayload(payload.entregaHasta),

        fechaPatentamiento: validarPayload(payload.fechaPatentamiento),
        fechaEntrega: validarPayload(payload.fechaEntrega)
      })

      const response = await fetch(`${API_URL}/comisionesVentasSalon/ventas?${params.toString()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        }
      })
      if (!response.ok) {
        const errorData = await response.json()
        throw new Error(errorData.message || 'Error en la solicitud')
      }
      return response.json()
    },

    async getSucursalesDeVenta({}, payload) {
      const params = new URLSearchParams({
        empresa: validarPayload(payload)
      })

      const response = await fetch(
        `${API_URL}/comisionesVentasSalon/sucursales?${params.toString()}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          }
        }
      )

      if (!response.ok) {
        const errorData = await response.json()
        throw new Error(errorData.message || 'Error en la solicitud')
      }

      return response.json()
    }
  }
}
