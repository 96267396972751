import { API_URL } from '../../../config'
import store from '../../store'

export default {
  namespaced: true,
  actions: {
    async getRecibos({}, payload) {
      const params = new URLSearchParams({
        ...payload
      })

      const response = await fetch(
        `${API_URL}/recibos-combustible?${params.toString()}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          }
        }
      )
      return response.json()
    },

    async getPermisos() {
      const response = await fetch(`${API_URL}/recibos-combustible/permisos`, {
        method: 'GET',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        }
      })
      return response.json()
    },

    async getSectores() {
      const response = await fetch(`${API_URL}/recibos-combustible/sectores`, {
        method: 'GET',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        }
      })
      return response.json()
    },

    async getTiposCombustible() {
      const response = await fetch(
        `${API_URL}/recibos-combustible/tipos-combustible`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          }
        }
      )
      return response.json()
    },

    async nuevoRecibo({}, payload) {
      const response = await fetch(
        `${API_URL}/recibos-combustible/nuevo-recibo`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          },
          body: JSON.stringify(payload)
        }
      )
      return response.json()
    },

    async getPlantillaOrdenDeCompra() {
      const response = await fetch(
        `${API_URL}/recibos-combustible/plantilla-orden`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          }
        }
      )
      return response.json()
    },

    async getInfoOrden({}, payload) {
      const params = new URLSearchParams({
        ...payload
      })

      const response = await fetch(
        `${API_URL}/recibos-combustible/info-orden?${params.toString()}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          }
        }
      )
      return response.json()
    },

    async auditarRecibo({}, payload){
      const response = await fetch(
        `${API_URL}/recibos-combustible/auditar`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          },
          body: JSON.stringify(payload)
        }
      )
      return response.json()
    },

    async anularRecibo({}, payload){
      const response = await fetch(
        `${API_URL}/recibos-combustible/anular`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          },
          body: JSON.stringify(payload)
        }
      )
      return response.json()
    },

    async editarRecibo({}, payload){
      const response = await fetch(
        `${API_URL}/recibos-combustible/editar`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          },
          body: JSON.stringify(payload)
        }
      )
      return response.json()
    },
  }
}
