<template>
    <v-dialog v-model="dialog" width="800" scrollable>
        <v-card>
            <v-card-title class="pb-0" style="word-break: normal;">
                ¿Cómo habilitar notificaciones?
                <v-spacer></v-spacer>
                <v-btn icon @click="dialog = false">
                    <v-icon>fas fa-times</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-title class="pa-0">
                <v-tabs v-model="tab" :vertical="$vuetify.breakpoint.xs">
                    <v-tab>
                        <v-icon left>fab fa-chrome</v-icon>
                        Chrome
                    </v-tab>
                    <v-tab>
                        <v-icon left>fab fa-firefox-browser</v-icon>
                        Firefox
                    </v-tab>
                    <v-tab>
                        Brave
                    </v-tab>
                </v-tabs>
            </v-card-title>
            <v-card-text class="pa-0" style="font-size: 18px;">
                <v-tabs-items v-model="tab" class="pa-6">
                    <!-- chrome -->
                    <v-tab-item>
                        <v-row class="d-flex justify-center">
                            <v-col cols="12" class="pa-0">
                                Si es la primera vez que ingresa a Jarvis 2 desde Chrome, <strong>permita</strong> las
                                notificaiones.
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-img contain :src="require('../assets/notificaciones/chrome-1.webp')"></v-img>
                            </v-col>
                            <v-col cols="12" class="pa-0">
                                Si bloqueó las notificaciones previamente, debe hacer click en el <strong>icono de
                                    candado</strong> <v-icon class="mb-1" small>fas fa-lock</v-icon>
                                ubicado a la izquierda del enlace de la página <strong class="error--text">(1)</strong>
                                y luego hacer click en el "<strong>switch</strong>"
                                para habilitar las notificaciones <strong class="error--text">(2)</strong>.
                            </v-col>
                            <v-col cols="12" sm="7">
                                <v-img :src="require('../assets/notificaciones/chrome-2.webp')"></v-img>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <!-- firefox -->
                    <v-tab-item>
                        <v-row class="d-flex justify-center">
                            <v-col cols="12" class="pa-0">
                                Si es la primera vez que ingresa a Jarvis 2 desde Firefox, haga click sobre el icono de
                                mensajes <v-icon class="mb-1" small>far fa-comment-alt</v-icon>
                                ubicado a la izquierda del enlace de la página <strong class="error--text">(1)</strong>
                                y luego <strong>permita</strong> las notificaiones
                                <strong class="error--text">(2)</strong>.
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-img :src="require('../assets/notificaciones/firefox-1.webp')"></v-img>
                            </v-col>
                            <v-col cols="12" class="pa-0">
                                Si bloqueó las notificaciones previamente, debe hacer click en el <strong>icono de
                                    mensajes</strong> <v-icon class="mb-1" small>far fa-comment-alt</v-icon>
                                ubicado a la izquierda del enlace de la pagina, luego hacer click sobre el botón
                                <strong>Bloqueado</strong> y refrescar la página con <strong>F5</strong>.
                            </v-col>
                            <v-col cols="12" sm="7">
                                <v-img :src="require('../assets/notificaciones/firefox-2.webp')"></v-img>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <!-- brave -->
                    <v-tab-item>
                        <v-row class="d-flex justify-center">
                            <v-col cols="12" class="pa-0">
                                Si es la primera vez que ingresa a Jarvis 2 desde Brave, seleccione <strong>para
                                    siempre</strong> en "Recordar mi decisión" y
                                luego <strong>permita</strong> las notificaiones.
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-img :src="require('../assets/notificaciones/brave-1.webp')"></v-img>
                            </v-col>
                            <v-col cols="12" class="pa-0">
                                Si bloqueó las notificaciones previamente, debe hacer click en el <strong>icono de
                                    candado</strong> <v-icon class="mb-1" small>fas fa-lock</v-icon>
                                ubicado a la izquierda del enlace de la página <strong class="error--text">(1)</strong>
                                y luego hacer click en el "<strong>switch</strong>"
                                para habilitar las notificaciones <strong class="error--text">(2)</strong>.
                            </v-col>
                            <v-col cols="12" sm="7">
                                <v-img :src="require('../assets/notificaciones/chrome-2.webp')"></v-img>
                            </v-col>
                            <v-col cols="12" class="pa-0">
                                Adicionalmente, en este navegador, debe habilitar un permiso en <strong>Configuración ->
                                    Privacidad y Seguridad</strong> <strong class="success--text">(1)</strong>.
                                Habilite la opción <strong>Usar servicios de Google para mensajes de inserción</strong>
                                y luego haga click en el botón
                                <strong>Reiniciar</strong> <strong class="success--text">(2)</strong>.
                            </v-col>
                            <v-col cols="12">
                                <v-img :src="require('../assets/notificaciones/brave-2.webp')"></v-img>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs-items>
            </v-card-text>
            <v-card-actions class="d-flex justify-end pt-0 pb-4 px-6">
                <v-btn color="secondary" @click="dialog = false">
                    Volver
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data() {
        return {
            tab: null
        }
    },
    props: {
        value: Boolean
    },
    computed: {
        dialog: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    }
}
</script>